<p-blockUI [blocked]="(loading$ | async) ?? false"></p-blockUI>
<p-toast position="bottom-right"></p-toast>
<p-confirmDialog
    [style]="{ width: '30vw', minWidth: '400px', maxWidth: '600px' }"
    [baseZIndex]="1000"
    styleClass="confirm-dialog"
    data-cy="confirm-dialog"
></p-confirmDialog>

<router-outlet></router-outlet>
