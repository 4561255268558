/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { banEditorialUser } from '../fn/editorial/ban-editorial-user';
import { BanEditorialUser$Params } from '../fn/editorial/ban-editorial-user';
import { CompanyCategoriesResponsePaging } from '../models/company-categories-response-paging';
import { CompanyCategoryLanguagesResponse } from '../models/company-category-languages-response';
import { CompanyCategoryResponse } from '../models/company-category-response';
import { createCompanyCategory } from '../fn/editorial/create-company-category';
import { CreateCompanyCategory$Params } from '../fn/editorial/create-company-category';
import { createEditorialCompany } from '../fn/editorial/create-editorial-company';
import { CreateEditorialCompany$Params } from '../fn/editorial/create-editorial-company';
import { createEditorialCompanyDiscount } from '../fn/editorial/create-editorial-company-discount';
import { CreateEditorialCompanyDiscount$Params } from '../fn/editorial/create-editorial-company-discount';
import { createPostCategory } from '../fn/editorial/create-post-category';
import { CreatePostCategory$Params } from '../fn/editorial/create-post-category';
import { deleteEditorialCompany } from '../fn/editorial/delete-editorial-company';
import { DeleteEditorialCompany$Params } from '../fn/editorial/delete-editorial-company';
import { deleteEditorialCompanyDiscount } from '../fn/editorial/delete-editorial-company-discount';
import { DeleteEditorialCompanyDiscount$Params } from '../fn/editorial/delete-editorial-company-discount';
import { deleteUserAdmin } from '../fn/editorial/delete-user-admin';
import { DeleteUserAdmin$Params } from '../fn/editorial/delete-user-admin';
import { EditorialActivityDetailResponse } from '../models/editorial-activity-detail-response';
import { EditorialActivityResponsePaging } from '../models/editorial-activity-response-paging';
import { EditorialCompaniesResponsePaging } from '../models/editorial-companies-response-paging';
import { EditorialCompanyDiscountResponse } from '../models/editorial-company-discount-response';
import { EditorialCompanyDiscountResponsePaging } from '../models/editorial-company-discount-response-paging';
import { EditorialCompanyResponse } from '../models/editorial-company-response';
import { EditorialPostCategoryResponse } from '../models/editorial-post-category-response';
import { FinancialTransactionResponsePaging } from '../models/financial-transaction-response-paging';
import { getCompanyCategory } from '../fn/editorial/get-company-category';
import { GetCompanyCategory$Params } from '../fn/editorial/get-company-category';
import { getEditorialActivities } from '../fn/editorial/get-editorial-activities';
import { GetEditorialActivities$Params } from '../fn/editorial/get-editorial-activities';
import { getEditorialActivity } from '../fn/editorial/get-editorial-activity';
import { GetEditorialActivity$Params } from '../fn/editorial/get-editorial-activity';
import { getEditorialCompanies } from '../fn/editorial/get-editorial-companies';
import { GetEditorialCompanies$Params } from '../fn/editorial/get-editorial-companies';
import { getEditorialCompaniesXlsx } from '../fn/editorial/get-editorial-companies-xlsx';
import { GetEditorialCompaniesXlsx$Params } from '../fn/editorial/get-editorial-companies-xlsx';
import { getEditorialCompany } from '../fn/editorial/get-editorial-company';
import { GetEditorialCompany$Params } from '../fn/editorial/get-editorial-company';
import { getEditorialCompanyByInvitationCode } from '../fn/editorial/get-editorial-company-by-invitation-code';
import { GetEditorialCompanyByInvitationCode$Params } from '../fn/editorial/get-editorial-company-by-invitation-code';
import { getEditorialCompanyCategories } from '../fn/editorial/get-editorial-company-categories';
import { GetEditorialCompanyCategories$Params } from '../fn/editorial/get-editorial-company-categories';
import { getEditorialCompanyCategoriesLanguages } from '../fn/editorial/get-editorial-company-categories-languages';
import { GetEditorialCompanyCategoriesLanguages$Params } from '../fn/editorial/get-editorial-company-categories-languages';
import { getEditorialCompanyDiscount } from '../fn/editorial/get-editorial-company-discount';
import { GetEditorialCompanyDiscount$Params } from '../fn/editorial/get-editorial-company-discount';
import { getEditorialCompanyDiscounts } from '../fn/editorial/get-editorial-company-discounts';
import { GetEditorialCompanyDiscounts$Params } from '../fn/editorial/get-editorial-company-discounts';
import { getEditorialPostCategories } from '../fn/editorial/get-editorial-post-categories';
import { GetEditorialPostCategories$Params } from '../fn/editorial/get-editorial-post-categories';
import { getEditorialPostCategoriesLanguages } from '../fn/editorial/get-editorial-post-categories-languages';
import { GetEditorialPostCategoriesLanguages$Params } from '../fn/editorial/get-editorial-post-categories-languages';
import { getFinancialTransactions } from '../fn/editorial/get-financial-transactions';
import { GetFinancialTransactions$Params } from '../fn/editorial/get-financial-transactions';
import { getFinancialTransactionsXlsx } from '../fn/editorial/get-financial-transactions-xlsx';
import { GetFinancialTransactionsXlsx$Params } from '../fn/editorial/get-financial-transactions-xlsx';
import { getLatestPostsApproved } from '../fn/editorial/get-latest-posts-approved';
import { GetLatestPostsApproved$Params } from '../fn/editorial/get-latest-posts-approved';
import { getLatestPostsToApprove } from '../fn/editorial/get-latest-posts-to-approve';
import { GetLatestPostsToApprove$Params } from '../fn/editorial/get-latest-posts-to-approve';
import { getLatestSubscribers } from '../fn/editorial/get-latest-subscribers';
import { GetLatestSubscribers$Params } from '../fn/editorial/get-latest-subscribers';
import { getPostCategoryDetail } from '../fn/editorial/get-post-category-detail';
import { GetPostCategoryDetail$Params } from '../fn/editorial/get-post-category-detail';
import { getUserAdmin } from '../fn/editorial/get-user-admin';
import { GetUserAdmin$Params } from '../fn/editorial/get-user-admin';
import { getUserAdmins } from '../fn/editorial/get-user-admins';
import { GetUserAdmins$Params } from '../fn/editorial/get-user-admins';
import { getUserPermissions } from '../fn/editorial/get-user-permissions';
import { GetUserPermissions$Params } from '../fn/editorial/get-user-permissions';
import { isEmailAvailable } from '../fn/editorial/is-email-available';
import { IsEmailAvailable$Params } from '../fn/editorial/is-email-available';
import { LatestPostsApprovedDto } from '../models/latest-posts-approved-dto';
import { LatestPostsToApproveDto } from '../models/latest-posts-to-approve-dto';
import { LatestSubscribersDto } from '../models/latest-subscribers-dto';
import { markBankTransferTransactionPaid } from '../fn/editorial/mark-bank-transfer-transaction-paid';
import { MarkBankTransferTransactionPaid$Params } from '../fn/editorial/mark-bank-transfer-transaction-paid';
import { PostCategoryLanguagesResponse } from '../models/post-category-languages-response';
import { PostCategoryResponsePaging } from '../models/post-category-response-paging';
import { publishActivity } from '../fn/editorial/publish-activity';
import { PublishActivity$Params } from '../fn/editorial/publish-activity';
import { rejectActivity } from '../fn/editorial/reject-activity';
import { RejectActivity$Params } from '../fn/editorial/reject-activity';
import { unBanEditorialUser } from '../fn/editorial/un-ban-editorial-user';
import { UnBanEditorialUser$Params } from '../fn/editorial/un-ban-editorial-user';
import { updateCompanyCategory } from '../fn/editorial/update-company-category';
import { UpdateCompanyCategory$Params } from '../fn/editorial/update-company-category';
import { updateEditorialCompany } from '../fn/editorial/update-editorial-company';
import { UpdateEditorialCompany$Params } from '../fn/editorial/update-editorial-company';
import { updateEditorialCompanyDiscount } from '../fn/editorial/update-editorial-company-discount';
import { UpdateEditorialCompanyDiscount$Params } from '../fn/editorial/update-editorial-company-discount';
import { updatePostCategory } from '../fn/editorial/update-post-category';
import { UpdatePostCategory$Params } from '../fn/editorial/update-post-category';
import { updateUserAdmin } from '../fn/editorial/update-user-admin';
import { UpdateUserAdmin$Params } from '../fn/editorial/update-user-admin';
import { updateUserPermissions } from '../fn/editorial/update-user-permissions';
import { UpdateUserPermissions$Params } from '../fn/editorial/update-user-permissions';
import { UserAdminResponse } from '../models/user-admin-response';
import { UserAdminResponsePaging } from '../models/user-admin-response-paging';

@Injectable({ providedIn: 'root' })
export class EditorialService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `banEditorialUser()` */
  static readonly BanEditorialUserPath = '/api/editorial/user-admins/{userId}/ban';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `banEditorialUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  banEditorialUser$Response(params: BanEditorialUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return banEditorialUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `banEditorialUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  banEditorialUser(params: BanEditorialUser$Params, context?: HttpContext): Observable<void> {
    return this.banEditorialUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUserAdmin()` */
  static readonly GetUserAdminPath = '/api/editorial/user-admins/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAdmin$Response(params: GetUserAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAdminResponse>> {
    return getUserAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAdmin(params: GetUserAdmin$Params, context?: HttpContext): Observable<UserAdminResponse> {
    return this.getUserAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAdminResponse>): UserAdminResponse => r.body)
    );
  }

  /** Path part for operation `deleteUserAdmin()` */
  static readonly DeleteUserAdminPath = '/api/editorial/user-admins/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserAdmin$Response(params: DeleteUserAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUserAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserAdmin(params: DeleteUserAdmin$Params, context?: HttpContext): Observable<void> {
    return this.deleteUserAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUserAdmins()` */
  static readonly GetUserAdminsPath = '/api/editorial/user-admins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAdmins()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAdmins$Response(params: GetUserAdmins$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAdminResponsePaging>> {
    return getUserAdmins(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAdmins$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAdmins(params: GetUserAdmins$Params, context?: HttpContext): Observable<UserAdminResponsePaging> {
    return this.getUserAdmins$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAdminResponsePaging>): UserAdminResponsePaging => r.body)
    );
  }

  /** Path part for operation `getUserPermissions()` */
  static readonly GetUserPermissionsPath = '/api/editorial/user-admins/{userId}/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPermissions$Response(params: GetUserPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getUserPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPermissions(params: GetUserPermissions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getUserPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `updateUserPermissions()` */
  static readonly UpdateUserPermissionsPath = '/api/editorial/user-admins/{userId}/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPermissions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPermissions$Response(params: UpdateUserPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateUserPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserPermissions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPermissions(params: UpdateUserPermissions$Params, context?: HttpContext): Observable<void> {
    return this.updateUserPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `isEmailAvailable()` */
  static readonly IsEmailAvailablePath = '/api/editorial/user-admins/{userId}/email-available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isEmailAvailable()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isEmailAvailable$Response(params: IsEmailAvailable$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isEmailAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isEmailAvailable$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  isEmailAvailable(params: IsEmailAvailable$Params, context?: HttpContext): Observable<boolean> {
    return this.isEmailAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `unBanEditorialUser()` */
  static readonly UnBanEditorialUserPath = '/api/editorial/user-admins/{userId}/unban';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unBanEditorialUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  unBanEditorialUser$Response(params: UnBanEditorialUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unBanEditorialUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unBanEditorialUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unBanEditorialUser(params: UnBanEditorialUser$Params, context?: HttpContext): Observable<void> {
    return this.unBanEditorialUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateUserAdmin()` */
  static readonly UpdateUserAdminPath = '/api/editorial/user-admins/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserAdmin$Response(params: UpdateUserAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAdminResponse>> {
    return updateUserAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserAdmin(params: UpdateUserAdmin$Params, context?: HttpContext): Observable<UserAdminResponse> {
    return this.updateUserAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserAdminResponse>): UserAdminResponse => r.body)
    );
  }

  /** Path part for operation `getLatestPostsApproved()` */
  static readonly GetLatestPostsApprovedPath = '/api/editorial/latest-posts-approved';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestPostsApproved()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestPostsApproved$Response(params?: GetLatestPostsApproved$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LatestPostsApprovedDto>>> {
    return getLatestPostsApproved(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestPostsApproved$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestPostsApproved(params?: GetLatestPostsApproved$Params, context?: HttpContext): Observable<Array<LatestPostsApprovedDto>> {
    return this.getLatestPostsApproved$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LatestPostsApprovedDto>>): Array<LatestPostsApprovedDto> => r.body)
    );
  }

  /** Path part for operation `getLatestPostsToApprove()` */
  static readonly GetLatestPostsToApprovePath = '/api/editorial/latest-posts-to-approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestPostsToApprove()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestPostsToApprove$Response(params?: GetLatestPostsToApprove$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LatestPostsToApproveDto>>> {
    return getLatestPostsToApprove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestPostsToApprove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestPostsToApprove(params?: GetLatestPostsToApprove$Params, context?: HttpContext): Observable<Array<LatestPostsToApproveDto>> {
    return this.getLatestPostsToApprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LatestPostsToApproveDto>>): Array<LatestPostsToApproveDto> => r.body)
    );
  }

  /** Path part for operation `getLatestSubscribers()` */
  static readonly GetLatestSubscribersPath = '/api/editorial/latest-subscribers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestSubscribers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSubscribers$Response(params?: GetLatestSubscribers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LatestSubscribersDto>>> {
    return getLatestSubscribers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestSubscribers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestSubscribers(params?: GetLatestSubscribers$Params, context?: HttpContext): Observable<Array<LatestSubscribersDto>> {
    return this.getLatestSubscribers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LatestSubscribersDto>>): Array<LatestSubscribersDto> => r.body)
    );
  }

  /** Path part for operation `getEditorialCompanies()` */
  static readonly GetEditorialCompaniesPath = '/api/editorial/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanies$Response(params: GetEditorialCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompaniesResponsePaging>> {
    return getEditorialCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanies(params: GetEditorialCompanies$Params, context?: HttpContext): Observable<EditorialCompaniesResponsePaging> {
    return this.getEditorialCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialCompaniesResponsePaging>): EditorialCompaniesResponsePaging => r.body)
    );
  }

  /** Path part for operation `createEditorialCompany()` */
  static readonly CreateEditorialCompanyPath = '/api/editorial/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEditorialCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEditorialCompany$Response(params: CreateEditorialCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return createEditorialCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEditorialCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEditorialCompany(params: CreateEditorialCompany$Params, context?: HttpContext): Observable<number> {
    return this.createEditorialCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `getEditorialCompanyDiscounts()` */
  static readonly GetEditorialCompanyDiscountsPath = '/api/editorial/companies/{id}/discounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompanyDiscounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyDiscounts$Response(params: GetEditorialCompanyDiscounts$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompanyDiscountResponsePaging>> {
    return getEditorialCompanyDiscounts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompanyDiscounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyDiscounts(params: GetEditorialCompanyDiscounts$Params, context?: HttpContext): Observable<EditorialCompanyDiscountResponsePaging> {
    return this.getEditorialCompanyDiscounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialCompanyDiscountResponsePaging>): EditorialCompanyDiscountResponsePaging => r.body)
    );
  }

  /** Path part for operation `createEditorialCompanyDiscount()` */
  static readonly CreateEditorialCompanyDiscountPath = '/api/editorial/companies/{id}/discounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEditorialCompanyDiscount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEditorialCompanyDiscount$Response(params: CreateEditorialCompanyDiscount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createEditorialCompanyDiscount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEditorialCompanyDiscount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEditorialCompanyDiscount(params: CreateEditorialCompanyDiscount$Params, context?: HttpContext): Observable<void> {
    return this.createEditorialCompanyDiscount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEditorialCompany()` */
  static readonly GetEditorialCompanyPath = '/api/editorial/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompany$Response(params: GetEditorialCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompanyResponse>> {
    return getEditorialCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompany(params: GetEditorialCompany$Params, context?: HttpContext): Observable<EditorialCompanyResponse> {
    return this.getEditorialCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialCompanyResponse>): EditorialCompanyResponse => r.body)
    );
  }

  /** Path part for operation `updateEditorialCompany()` */
  static readonly UpdateEditorialCompanyPath = '/api/editorial/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEditorialCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEditorialCompany$Response(params: UpdateEditorialCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompanyResponse>> {
    return updateEditorialCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEditorialCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEditorialCompany(params: UpdateEditorialCompany$Params, context?: HttpContext): Observable<EditorialCompanyResponse> {
    return this.updateEditorialCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialCompanyResponse>): EditorialCompanyResponse => r.body)
    );
  }

  /** Path part for operation `deleteEditorialCompany()` */
  static readonly DeleteEditorialCompanyPath = '/api/editorial/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEditorialCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEditorialCompany$Response(params: DeleteEditorialCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEditorialCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEditorialCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEditorialCompany(params: DeleteEditorialCompany$Params, context?: HttpContext): Observable<void> {
    return this.deleteEditorialCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEditorialCompanyDiscount()` */
  static readonly GetEditorialCompanyDiscountPath = '/api/editorial/companies/{companyId}/discounts/{discountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompanyDiscount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyDiscount$Response(params: GetEditorialCompanyDiscount$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompanyDiscountResponse>> {
    return getEditorialCompanyDiscount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompanyDiscount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyDiscount(params: GetEditorialCompanyDiscount$Params, context?: HttpContext): Observable<EditorialCompanyDiscountResponse> {
    return this.getEditorialCompanyDiscount$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialCompanyDiscountResponse>): EditorialCompanyDiscountResponse => r.body)
    );
  }

  /** Path part for operation `updateEditorialCompanyDiscount()` */
  static readonly UpdateEditorialCompanyDiscountPath = '/api/editorial/companies/{companyId}/discounts/{discountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEditorialCompanyDiscount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEditorialCompanyDiscount$Response(params: UpdateEditorialCompanyDiscount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateEditorialCompanyDiscount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateEditorialCompanyDiscount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEditorialCompanyDiscount(params: UpdateEditorialCompanyDiscount$Params, context?: HttpContext): Observable<void> {
    return this.updateEditorialCompanyDiscount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteEditorialCompanyDiscount()` */
  static readonly DeleteEditorialCompanyDiscountPath = '/api/editorial/companies/{companyId}/discounts/{discountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEditorialCompanyDiscount()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEditorialCompanyDiscount$Response(params: DeleteEditorialCompanyDiscount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteEditorialCompanyDiscount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEditorialCompanyDiscount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEditorialCompanyDiscount(params: DeleteEditorialCompanyDiscount$Params, context?: HttpContext): Observable<void> {
    return this.deleteEditorialCompanyDiscount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEditorialCompanyByInvitationCode()` */
  static readonly GetEditorialCompanyByInvitationCodePath = '/api/editorial/companies/invitationCode/{invitationCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompanyByInvitationCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyByInvitationCode$Response(params: GetEditorialCompanyByInvitationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompanyResponse>> {
    return getEditorialCompanyByInvitationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompanyByInvitationCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyByInvitationCode(params: GetEditorialCompanyByInvitationCode$Params, context?: HttpContext): Observable<EditorialCompanyResponse> {
    return this.getEditorialCompanyByInvitationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialCompanyResponse>): EditorialCompanyResponse => r.body)
    );
  }

  /** Path part for operation `createCompanyCategory()` */
  static readonly CreateCompanyCategoryPath = '/api/editorial/company-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyCategory$Response(params: CreateCompanyCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createCompanyCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyCategory(params: CreateCompanyCategory$Params, context?: HttpContext): Observable<void> {
    return this.createCompanyCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createPostCategory()` */
  static readonly CreatePostCategoryPath = '/api/editorial/post-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPostCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPostCategory$Response(params: CreatePostCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createPostCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPostCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPostCategory(params: CreatePostCategory$Params, context?: HttpContext): Observable<void> {
    return this.createPostCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCompanyCategory()` */
  static readonly GetCompanyCategoryPath = '/api/editorial/company-category/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyCategory$Response(params: GetCompanyCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyCategoryResponse>> {
    return getCompanyCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyCategory(params: GetCompanyCategory$Params, context?: HttpContext): Observable<CompanyCategoryResponse> {
    return this.getCompanyCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyCategoryResponse>): CompanyCategoryResponse => r.body)
    );
  }

  /** Path part for operation `updateCompanyCategory()` */
  static readonly UpdateCompanyCategoryPath = '/api/editorial/company-category/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyCategory$Response(params: UpdateCompanyCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateCompanyCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyCategory(params: UpdateCompanyCategory$Params, context?: HttpContext): Observable<void> {
    return this.updateCompanyCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getEditorialActivities()` */
  static readonly GetEditorialActivitiesPath = '/api/editorial/activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialActivities$Response(params: GetEditorialActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialActivityResponsePaging>> {
    return getEditorialActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialActivities(params: GetEditorialActivities$Params, context?: HttpContext): Observable<EditorialActivityResponsePaging> {
    return this.getEditorialActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialActivityResponsePaging>): EditorialActivityResponsePaging => r.body)
    );
  }

  /** Path part for operation `getEditorialActivity()` */
  static readonly GetEditorialActivityPath = '/api/editorial/activities/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialActivity$Response(params: GetEditorialActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialActivityDetailResponse>> {
    return getEditorialActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialActivity(params: GetEditorialActivity$Params, context?: HttpContext): Observable<EditorialActivityDetailResponse> {
    return this.getEditorialActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialActivityDetailResponse>): EditorialActivityDetailResponse => r.body)
    );
  }

  /** Path part for operation `getEditorialCompaniesXlsx()` */
  static readonly GetEditorialCompaniesXlsxPath = '/api/editorial/companies/xlsx';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompaniesXlsx()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompaniesXlsx$Response(params?: GetEditorialCompaniesXlsx$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getEditorialCompaniesXlsx(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompaniesXlsx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompaniesXlsx(params?: GetEditorialCompaniesXlsx$Params, context?: HttpContext): Observable<Blob> {
    return this.getEditorialCompaniesXlsx$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getEditorialCompanyCategories()` */
  static readonly GetEditorialCompanyCategoriesPath = '/api/editorial/company-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompanyCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyCategories$Response(params: GetEditorialCompanyCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyCategoriesResponsePaging>> {
    return getEditorialCompanyCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompanyCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyCategories(params: GetEditorialCompanyCategories$Params, context?: HttpContext): Observable<CompanyCategoriesResponsePaging> {
    return this.getEditorialCompanyCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyCategoriesResponsePaging>): CompanyCategoriesResponsePaging => r.body)
    );
  }

  /** Path part for operation `getEditorialCompanyCategoriesLanguages()` */
  static readonly GetEditorialCompanyCategoriesLanguagesPath = '/api/editorial/company-categories-languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialCompanyCategoriesLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyCategoriesLanguages$Response(params?: GetEditorialCompanyCategoriesLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CompanyCategoryLanguagesResponse>>> {
    return getEditorialCompanyCategoriesLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialCompanyCategoriesLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialCompanyCategoriesLanguages(params?: GetEditorialCompanyCategoriesLanguages$Params, context?: HttpContext): Observable<Array<CompanyCategoryLanguagesResponse>> {
    return this.getEditorialCompanyCategoriesLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CompanyCategoryLanguagesResponse>>): Array<CompanyCategoryLanguagesResponse> => r.body)
    );
  }

  /** Path part for operation `getEditorialPostCategories()` */
  static readonly GetEditorialPostCategoriesPath = '/api/editorial/post-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialPostCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostCategories$Response(params: GetEditorialPostCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<PostCategoryResponsePaging>> {
    return getEditorialPostCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialPostCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostCategories(params: GetEditorialPostCategories$Params, context?: HttpContext): Observable<PostCategoryResponsePaging> {
    return this.getEditorialPostCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostCategoryResponsePaging>): PostCategoryResponsePaging => r.body)
    );
  }

  /** Path part for operation `getEditorialPostCategoriesLanguages()` */
  static readonly GetEditorialPostCategoriesLanguagesPath = '/api/editorial/post-categories-languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEditorialPostCategoriesLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostCategoriesLanguages$Response(params?: GetEditorialPostCategoriesLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PostCategoryLanguagesResponse>>> {
    return getEditorialPostCategoriesLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEditorialPostCategoriesLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEditorialPostCategoriesLanguages(params?: GetEditorialPostCategoriesLanguages$Params, context?: HttpContext): Observable<Array<PostCategoryLanguagesResponse>> {
    return this.getEditorialPostCategoriesLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostCategoryLanguagesResponse>>): Array<PostCategoryLanguagesResponse> => r.body)
    );
  }

  /** Path part for operation `getFinancialTransactions()` */
  static readonly GetFinancialTransactionsPath = '/api/editorial/transactions/xlsx';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancialTransactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialTransactions$Response(params: GetFinancialTransactions$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialTransactionResponsePaging>> {
    return getFinancialTransactions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancialTransactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialTransactions(params: GetFinancialTransactions$Params, context?: HttpContext): Observable<FinancialTransactionResponsePaging> {
    return this.getFinancialTransactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialTransactionResponsePaging>): FinancialTransactionResponsePaging => r.body)
    );
  }

  /** Path part for operation `getFinancialTransactionsXlsx()` */
  static readonly GetFinancialTransactionsXlsxPath = '/api/editorial/transactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancialTransactionsXlsx()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialTransactionsXlsx$Response(params?: GetFinancialTransactionsXlsx$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getFinancialTransactionsXlsx(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancialTransactionsXlsx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialTransactionsXlsx(params?: GetFinancialTransactionsXlsx$Params, context?: HttpContext): Observable<Blob> {
    return this.getFinancialTransactionsXlsx$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getPostCategoryDetail()` */
  static readonly GetPostCategoryDetailPath = '/api/editorial/post-category/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPostCategoryDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostCategoryDetail$Response(params: GetPostCategoryDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialPostCategoryResponse>> {
    return getPostCategoryDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPostCategoryDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostCategoryDetail(params: GetPostCategoryDetail$Params, context?: HttpContext): Observable<EditorialPostCategoryResponse> {
    return this.getPostCategoryDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditorialPostCategoryResponse>): EditorialPostCategoryResponse => r.body)
    );
  }

  /** Path part for operation `updatePostCategory()` */
  static readonly UpdatePostCategoryPath = '/api/editorial/post-category/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePostCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePostCategory$Response(params: UpdatePostCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updatePostCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePostCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePostCategory(params: UpdatePostCategory$Params, context?: HttpContext): Observable<void> {
    return this.updatePostCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markBankTransferTransactionPaid()` */
  static readonly MarkBankTransferTransactionPaidPath = '/api/payment/{id}/mark-paid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markBankTransferTransactionPaid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markBankTransferTransactionPaid$Response(params: MarkBankTransferTransactionPaid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markBankTransferTransactionPaid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markBankTransferTransactionPaid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markBankTransferTransactionPaid(params: MarkBankTransferTransactionPaid$Params, context?: HttpContext): Observable<void> {
    return this.markBankTransferTransactionPaid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `publishActivity()` */
  static readonly PublishActivityPath = '/api/editorial/activities/{id}/publish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishActivity$Response(params: PublishActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return publishActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishActivity(params: PublishActivity$Params, context?: HttpContext): Observable<void> {
    return this.publishActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `rejectActivity()` */
  static readonly RejectActivityPath = '/api/editorial/activities/{id}/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectActivity$Response(params: RejectActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rejectActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  rejectActivity(params: RejectActivity$Params, context?: HttpContext): Observable<void> {
    return this.rejectActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
