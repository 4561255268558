/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changePassword } from '../fn/user/change-password';
import { ChangePassword$Params } from '../fn/user/change-password';
import { checkUserInvitationCode } from '../fn/user/check-user-invitation-code';
import { CheckUserInvitationCode$Params } from '../fn/user/check-user-invitation-code';
import { getProfileData } from '../fn/user/get-profile-data';
import { GetProfileData$Params } from '../fn/user/get-profile-data';
import { registerUser } from '../fn/user/register-user';
import { RegisterUser$Params } from '../fn/user/register-user';
import { updateCoverPicture } from '../fn/user/update-cover-picture';
import { UpdateCoverPicture$Params } from '../fn/user/update-cover-picture';
import { UpdatePictureResponse } from '../models/update-picture-response';
import { updateProfileData } from '../fn/user/update-profile-data';
import { UpdateProfileData$Params } from '../fn/user/update-profile-data';
import { updateProfilePicture } from '../fn/user/update-profile-picture';
import { UpdateProfilePicture$Params } from '../fn/user/update-profile-picture';
import { UserProfileDataResponse } from '../models/user-profile-data-response';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changePassword()` */
  static readonly ChangePasswordPath = '/api/user/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: ChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: ChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.changePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkUserInvitationCode()` */
  static readonly CheckUserInvitationCodePath = '/api/user/invitation-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUserInvitationCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserInvitationCode$Response(params: CheckUserInvitationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkUserInvitationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUserInvitationCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserInvitationCode(params: CheckUserInvitationCode$Params, context?: HttpContext): Observable<void> {
    return this.checkUserInvitationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getProfileData()` */
  static readonly GetProfileDataPath = '/api/user/profile-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileData$Response(params?: GetProfileData$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfileDataResponse>> {
    return getProfileData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfileData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileData(params?: GetProfileData$Params, context?: HttpContext): Observable<UserProfileDataResponse> {
    return this.getProfileData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileDataResponse>): UserProfileDataResponse => r.body)
    );
  }

  /** Path part for operation `updateProfileData()` */
  static readonly UpdateProfileDataPath = '/api/user/profile-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfileData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileData$Response(params: UpdateProfileData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateProfileData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProfileData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileData(params: UpdateProfileData$Params, context?: HttpContext): Observable<void> {
    return this.updateProfileData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerUser()` */
  static readonly RegisterUserPath = '/api/user/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser$Response(params: RegisterUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser(params: RegisterUser$Params, context?: HttpContext): Observable<void> {
    return this.registerUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCoverPicture()` */
  static readonly UpdateCoverPicturePath = '/api/user/cover';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCoverPicture()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCoverPicture$Response(params?: UpdateCoverPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdatePictureResponse>> {
    return updateCoverPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCoverPicture$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCoverPicture(params?: UpdateCoverPicture$Params, context?: HttpContext): Observable<UpdatePictureResponse> {
    return this.updateCoverPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdatePictureResponse>): UpdatePictureResponse => r.body)
    );
  }

  /** Path part for operation `updateProfilePicture()` */
  static readonly UpdateProfilePicturePath = '/api/user/profile-picture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfilePicture()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateProfilePicture$Response(params?: UpdateProfilePicture$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdatePictureResponse>> {
    return updateProfilePicture(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProfilePicture$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateProfilePicture(params?: UpdateProfilePicture$Params, context?: HttpContext): Observable<UpdatePictureResponse> {
    return this.updateProfilePicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdatePictureResponse>): UpdatePictureResponse => r.body)
    );
  }

}
