/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActivityFeedResponse } from '../models/activity-feed-response';
import { ActivityReactionDto } from '../models/activity-reaction-dto';
import { ActivityResponse } from '../models/activity-response';
import { ActivityTranslationResponse } from '../models/activity-translation-response';
import { AvailableFiltersResponse } from '../models/available-filters-response';
import { AvailablePostLanguageResponse } from '../models/available-post-language-response';
import { bookmarkActivity } from '../fn/activity/bookmark-activity';
import { BookmarkActivity$Params } from '../fn/activity/bookmark-activity';
import { createPost } from '../fn/activity/create-post';
import { CreatePost$Params } from '../fn/activity/create-post';
import { editPost } from '../fn/activity/edit-post';
import { EditPost$Params } from '../fn/activity/edit-post';
import { getActivities } from '../fn/activity/get-activities';
import { GetActivities$Params } from '../fn/activity/get-activities';
import { getActivitiesToCopy } from '../fn/activity/get-activities-to-copy';
import { GetActivitiesToCopy$Params } from '../fn/activity/get-activities-to-copy';
import { getActivity } from '../fn/activity/get-activity';
import { GetActivity$Params } from '../fn/activity/get-activity';
import { getActivityTranslation } from '../fn/activity/get-activity-translation';
import { GetActivityTranslation$Params } from '../fn/activity/get-activity-translation';
import { getActivityWithTranslations } from '../fn/activity/get-activity-with-translations';
import { GetActivityWithTranslations$Params } from '../fn/activity/get-activity-with-translations';
import { getAvailableCategories } from '../fn/activity/get-available-categories';
import { GetAvailableCategories$Params } from '../fn/activity/get-available-categories';
import { getAvailableFilters } from '../fn/activity/get-available-filters';
import { GetAvailableFilters$Params } from '../fn/activity/get-available-filters';
import { getBookmarkedActivities } from '../fn/activity/get-bookmarked-activities';
import { GetBookmarkedActivities$Params } from '../fn/activity/get-bookmarked-activities';
import { getCompanyActivities } from '../fn/activity/get-company-activities';
import { GetCompanyActivities$Params } from '../fn/activity/get-company-activities';
import { getDrafts } from '../fn/activity/get-drafts';
import { GetDrafts$Params } from '../fn/activity/get-drafts';
import { getPostCategory } from '../fn/activity/get-post-category';
import { GetPostCategory$Params } from '../fn/activity/get-post-category';
import { GetPostResponse } from '../models/get-post-response';
import { getReactions } from '../fn/activity/get-reactions';
import { GetReactions$Params } from '../fn/activity/get-reactions';
import { getRejected } from '../fn/activity/get-rejected';
import { GetRejected$Params } from '../fn/activity/get-rejected';
import { getSupportedPostLanguages } from '../fn/activity/get-supported-post-languages';
import { GetSupportedPostLanguages$Params } from '../fn/activity/get-supported-post-languages';
import { getTrendingActivities } from '../fn/activity/get-trending-activities';
import { GetTrendingActivities$Params } from '../fn/activity/get-trending-activities';
import { getUnlockedActivities } from '../fn/activity/get-unlocked-activities';
import { GetUnlockedActivities$Params } from '../fn/activity/get-unlocked-activities';
import { getUserActivities } from '../fn/activity/get-user-activities';
import { GetUserActivities$Params } from '../fn/activity/get-user-activities';
import { inviteToUnlockActivity } from '../fn/activity/invite-to-unlock-activity';
import { InviteToUnlockActivity$Params } from '../fn/activity/invite-to-unlock-activity';
import { likeActivity } from '../fn/activity/like-activity';
import { LikeActivity$Params } from '../fn/activity/like-activity';
import { mimicActivity } from '../fn/activity/mimic-activity';
import { MimicActivity$Params } from '../fn/activity/mimic-activity';
import { PostCategoryDetailResponse } from '../models/post-category-detail-response';
import { PostCategoryResponse } from '../models/post-category-response';
import { removeBookmark } from '../fn/activity/remove-bookmark';
import { RemoveBookmark$Params } from '../fn/activity/remove-bookmark';
import { removeLike } from '../fn/activity/remove-like';
import { RemoveLike$Params } from '../fn/activity/remove-like';
import { removeMimic } from '../fn/activity/remove-mimic';
import { RemoveMimic$Params } from '../fn/activity/remove-mimic';
import { unlockActivity } from '../fn/activity/unlock-activity';
import { UnlockActivity$Params } from '../fn/activity/unlock-activity';

@Injectable({ providedIn: 'root' })
export class ActivityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bookmarkActivity()` */
  static readonly BookmarkActivityPath = '/api/activities/{id}/bookmark';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookmarkActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  bookmarkActivity$Response(params: BookmarkActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bookmarkActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bookmarkActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bookmarkActivity(params: BookmarkActivity$Params, context?: HttpContext): Observable<void> {
    return this.bookmarkActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeBookmark()` */
  static readonly RemoveBookmarkPath = '/api/activities/{id}/bookmark';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeBookmark()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeBookmark$Response(params: RemoveBookmark$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeBookmark(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeBookmark$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeBookmark(params: RemoveBookmark$Params, context?: HttpContext): Observable<void> {
    return this.removeBookmark$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createPost()` */
  static readonly CreatePostPath = '/api/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createPost$Response(params?: CreatePost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createPost(params?: CreatePost$Params, context?: HttpContext): Observable<void> {
    return this.createPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `editPost()` */
  static readonly EditPostPath = '/api/post/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  editPost$Response(params: EditPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  editPost(params: EditPost$Params, context?: HttpContext): Observable<void> {
    return this.editPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getActivities()` */
  static readonly GetActivitiesPath = '/api/activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivities$Response(params: GetActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivities(params: GetActivities$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getActivitiesToCopy()` */
  static readonly GetActivitiesToCopyPath = '/api/activities/tocopy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivitiesToCopy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivitiesToCopy$Response(params: GetActivitiesToCopy$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getActivitiesToCopy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivitiesToCopy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivitiesToCopy(params: GetActivitiesToCopy$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getActivitiesToCopy$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getActivity()` */
  static readonly GetActivityPath = '/api/activities/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivity$Response(params: GetActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityResponse>> {
    return getActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivity(params: GetActivity$Params, context?: HttpContext): Observable<ActivityResponse> {
    return this.getActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityResponse>): ActivityResponse => r.body)
    );
  }

  /** Path part for operation `getActivityTranslation()` */
  static readonly GetActivityTranslationPath = '/api/activities/{id}/translation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityTranslation$Response(params: GetActivityTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<ActivityTranslationResponse>> {
    return getActivityTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivityTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityTranslation(params: GetActivityTranslation$Params, context?: HttpContext): Observable<ActivityTranslationResponse> {
    return this.getActivityTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityTranslationResponse>): ActivityTranslationResponse => r.body)
    );
  }

  /** Path part for operation `getActivityWithTranslations()` */
  static readonly GetActivityWithTranslationsPath = '/api/activity-with-translations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivityWithTranslations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityWithTranslations$Response(params: GetActivityWithTranslations$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPostResponse>> {
    return getActivityWithTranslations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivityWithTranslations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivityWithTranslations(params: GetActivityWithTranslations$Params, context?: HttpContext): Observable<GetPostResponse> {
    return this.getActivityWithTranslations$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPostResponse>): GetPostResponse => r.body)
    );
  }

  /** Path part for operation `getAvailableCategories()` */
  static readonly GetAvailableCategoriesPath = '/api/available-categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCategories$Response(params?: GetAvailableCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PostCategoryResponse>>> {
    return getAvailableCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCategories(params?: GetAvailableCategories$Params, context?: HttpContext): Observable<Array<PostCategoryResponse>> {
    return this.getAvailableCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PostCategoryResponse>>): Array<PostCategoryResponse> => r.body)
    );
  }

  /** Path part for operation `getAvailableFilters()` */
  static readonly GetAvailableFiltersPath = '/api/available-filters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableFilters$Response(params?: GetAvailableFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailableFiltersResponse>> {
    return getAvailableFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableFilters(params?: GetAvailableFilters$Params, context?: HttpContext): Observable<AvailableFiltersResponse> {
    return this.getAvailableFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailableFiltersResponse>): AvailableFiltersResponse => r.body)
    );
  }

  /** Path part for operation `getBookmarkedActivities()` */
  static readonly GetBookmarkedActivitiesPath = '/api/activities/bookmarked';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookmarkedActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookmarkedActivities$Response(params: GetBookmarkedActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getBookmarkedActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBookmarkedActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookmarkedActivities(params: GetBookmarkedActivities$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getBookmarkedActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getCompanyActivities()` */
  static readonly GetCompanyActivitiesPath = '/api/activities/company/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyActivities$Response(params: GetCompanyActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getCompanyActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyActivities(params: GetCompanyActivities$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getCompanyActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getDrafts()` */
  static readonly GetDraftsPath = '/api/activities/drafts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDrafts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrafts$Response(params: GetDrafts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getDrafts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDrafts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDrafts(params: GetDrafts$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getDrafts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getPostCategory()` */
  static readonly GetPostCategoryPath = '/api/post/category/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPostCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostCategory$Response(params: GetPostCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<PostCategoryDetailResponse>> {
    return getPostCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPostCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPostCategory(params: GetPostCategory$Params, context?: HttpContext): Observable<PostCategoryDetailResponse> {
    return this.getPostCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostCategoryDetailResponse>): PostCategoryDetailResponse => r.body)
    );
  }

  /** Path part for operation `getReactions()` */
  static readonly GetReactionsPath = '/api/activities/{id}/reactions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReactions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReactions$Response(params: GetReactions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityReactionDto>>> {
    return getReactions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReactions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReactions(params: GetReactions$Params, context?: HttpContext): Observable<Array<ActivityReactionDto>> {
    return this.getReactions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityReactionDto>>): Array<ActivityReactionDto> => r.body)
    );
  }

  /** Path part for operation `getRejected()` */
  static readonly GetRejectedPath = '/api/activities/rejected';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRejected()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRejected$Response(params: GetRejected$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getRejected(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRejected$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRejected(params: GetRejected$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getRejected$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getSupportedPostLanguages()` */
  static readonly GetSupportedPostLanguagesPath = '/api/post/supported-post-languages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportedPostLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportedPostLanguages$Response(params?: GetSupportedPostLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AvailablePostLanguageResponse>>> {
    return getSupportedPostLanguages(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupportedPostLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportedPostLanguages(params?: GetSupportedPostLanguages$Params, context?: HttpContext): Observable<Array<AvailablePostLanguageResponse>> {
    return this.getSupportedPostLanguages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AvailablePostLanguageResponse>>): Array<AvailablePostLanguageResponse> => r.body)
    );
  }

  /** Path part for operation `getTrendingActivities()` */
  static readonly GetTrendingActivitiesPath = '/api/trending-activities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrendingActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrendingActivities$Response(params: GetTrendingActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getTrendingActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTrendingActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrendingActivities(params: GetTrendingActivities$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getTrendingActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getUnlockedActivities()` */
  static readonly GetUnlockedActivitiesPath = '/api/activities/unlocked';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnlockedActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnlockedActivities$Response(params: GetUnlockedActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getUnlockedActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnlockedActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnlockedActivities(params: GetUnlockedActivities$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getUnlockedActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `getUserActivities()` */
  static readonly GetUserActivitiesPath = '/api/activities/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserActivities$Response(params: GetUserActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
    return getUserActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserActivities(params: GetUserActivities$Params, context?: HttpContext): Observable<Array<ActivityFeedResponse>> {
    return this.getUserActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityFeedResponse>>): Array<ActivityFeedResponse> => r.body)
    );
  }

  /** Path part for operation `inviteToUnlockActivity()` */
  static readonly InviteToUnlockActivityPath = '/api/activities/{id}/invite-to-unlock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteToUnlockActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  inviteToUnlockActivity$Response(params: InviteToUnlockActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return inviteToUnlockActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inviteToUnlockActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inviteToUnlockActivity(params: InviteToUnlockActivity$Params, context?: HttpContext): Observable<void> {
    return this.inviteToUnlockActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `likeActivity()` */
  static readonly LikeActivityPath = '/api/activities/{id}/like';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `likeActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  likeActivity$Response(params: LikeActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return likeActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `likeActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  likeActivity(params: LikeActivity$Params, context?: HttpContext): Observable<void> {
    return this.likeActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeLike()` */
  static readonly RemoveLikePath = '/api/activities/{id}/like';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeLike()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeLike$Response(params: RemoveLike$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeLike(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeLike$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeLike(params: RemoveLike$Params, context?: HttpContext): Observable<void> {
    return this.removeLike$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mimicActivity()` */
  static readonly MimicActivityPath = '/api/activities/{id}/mimic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mimicActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  mimicActivity$Response(params: MimicActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mimicActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mimicActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mimicActivity(params: MimicActivity$Params, context?: HttpContext): Observable<void> {
    return this.mimicActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeMimic()` */
  static readonly RemoveMimicPath = '/api/activities/{id}/mimic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMimic()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMimic$Response(params: RemoveMimic$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeMimic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeMimic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMimic(params: RemoveMimic$Params, context?: HttpContext): Observable<void> {
    return this.removeMimic$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unlockActivity()` */
  static readonly UnlockActivityPath = '/api/activities/{id}/unlock-activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlockActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockActivity$Response(params: UnlockActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unlockActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlockActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlockActivity(params: UnlockActivity$Params, context?: HttpContext): Observable<void> {
    return this.unlockActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
