import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { LoadingService } from '@app/ui/ui-loading';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'primeng/blockui';
import { CookieConsentService, LanguageService } from '@app/core/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import 'dayjs/locale/it';

@Component({
    selector: 'doable-root',
    templateUrl: './app-root.component.html',
    styleUrls: ['./app-root.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [BlockUIModule, ToastModule, ConfirmDialogModule, RouterOutlet, AsyncPipe],
})
export class AppRootComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);

    readonly loading$ = this.loadingService.loading$;

    constructor(
        private primengConfig: PrimeNGConfig,
        private loadingService: LoadingService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private cookieConsentService: CookieConsentService,
    ) {}

    ngOnInit(): void {
        this.languageService.initLanguage();
        this.cookieConsentService.init();

        this.primengConfig.ripple = false;

        // TODO: update primeng on locale change
        this.translateService
            .get('primeng')
            .pipe(
                tap((res) => this.primengConfig.setTranslation(res)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();

        this.languageService.currentLanguage$
            .pipe(
                tap((res) => {
                    dayjs.extend(isoWeek);
                    dayjs.extend(relativeTime);
                    dayjs.locale(res ?? undefined);
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }
}
