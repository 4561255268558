import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly storageKey: string = 'locale';

    private readonly _currentLanguage$ = new BehaviorSubject<string | null>(null);
    readonly currentLanguage$ = this._currentLanguage$.asObservable().pipe(distinctUntilChanged());

    constructor(private translateService: TranslateService) {}

    initLanguage(): void {
        this.setLanguage((localStorage.getItem(this.storageKey) as string) || this.translateService.defaultLang);
    }

    setLanguage(lang: string): void {
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);
        localStorage.setItem(this.storageKey, lang);
        this._currentLanguage$.next(lang);
    }
}
