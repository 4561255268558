import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OAuthEvent } from 'angular-oauth2-oidc/events';

export interface User {
    email: string;
    name?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    user$ = new BehaviorSubject<User | null>(null);

    private handleUserLogout(): void {
        this.router.navigateByUrl('/');
    }

    constructor(
        private oauthService: OAuthService,
        private router: Router,
    ) {
        window.addEventListener('storage', (event) => {
            if (event.key !== 'access_token' && event.key != null) {
                return;
            }

            console.warn('Noticed changes to access_token (most likely from another tab)');
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                this.handleUserLogout();
            }
        });

        this.oauthService.events.subscribe(() => {
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

        this.oauthService.events
            .pipe(filter((e) => ['token_received'].includes(e.type)))
            .subscribe(() => this.oauthService.loadUserProfile());

        this.oauthService.events
            .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe(() => this.handleUserLogout());

        this.oauthService.setupAutomaticSilentRefresh();
    }

    public runInitialLoginSequence(): Promise<void> {
        return this.oauthService
            .loadDiscoveryDocument()
            .then(() => this.oauthService.tryLogin())
            .then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }
                return this.oauthService
                    .refreshToken()
                    .then(() => Promise.resolve())
                    .catch((result) => {
                        console.error(result);
                        // Force the user to perform login
                        this.login();
                        return Promise.resolve();
                    });
            })
            .then(() => {
                this.user$.next(this.getUser());
                this.isDoneLoadingSubject$.next(true);
                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    let stateUrl = this.oauthService.state;
                    if (!stateUrl.startsWith('/')) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    this.router.navigateByUrl(stateUrl);
                }
            })
            .catch(() => this.isDoneLoadingSubject$.next(true));
    }

    public login(targetUrl?: string): void {
        this.oauthService.initLoginFlow(targetUrl || this.router.url);
    }

    public logout(): void {
        this.oauthService.logOut();
    }
    public refresh(): Observable<OAuthEvent> {
        return from(this.oauthService.silentRefresh());
    }
    public hasValidToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }
    private getUser(): User {
        return this.oauthService.getIdentityClaims() as User;
    }
}
