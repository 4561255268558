import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { toCamelCase } from '@app/core/utils';
import { AppException } from '@app/core/exceptions';

export type LabelProvider = (error: HttpErrorResponse) => {
    title?: string;
    detail?: string;
};
export type ToastOptions = { titleParams?: object; descriptionParams?: object };

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(
        private messageService: MessageService,
        private translateService: TranslateService,
    ) {}

    public error(description = 'errors.generic.detail', title?: string, options?: ToastOptions): void {
        this.openToast(title, description, 'error', options);
    }

    public httpError(error: HttpErrorResponse): void {
        try {
            const errorBody = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;
            this.error(errorBody.type);
        } catch {
            this.error();
        }
    }

    public appError(error: AppException): void {
        this.error(error.message + '.detail', error.message + '.title');
    }

    public warn(description: string, title: string | null, options?: ToastOptions): void {
        this.openToast(title, description, 'warn', options);
    }

    public success(description = 'notifications.genericSuccess.detail', title?: string, options?: ToastOptions): void {
        this.openToast(title, description, 'success', options);
    }

    public catchErrorWithToast<T>(): (source: Observable<T>) => Observable<T> {
        return catchError((error) => {
            this.httpError(error);
            return EMPTY;
        });
    }

    public withSuccess<T>(title?: string, detail?: string, options?: ToastOptions): (source: Observable<T>) => Observable<T> {
        return tap(() => {
            this.success(title ?? 'notifications.genericSuccess.title', detail ?? 'notifications.genericSuccess.detail', options);
        });
    }

    private openToast(title: string | null | undefined, description: string, severity: string, options?: ToastOptions): void {
        this.messageService.add({
            severity: severity,
            summary: title != null ? this.translateService.instant(title, options?.titleParams) : undefined,
            detail: this.translateService.instant(description, options?.descriptionParams),
        });
    }

    public defaultErrorsLabelProvider(error: HttpErrorResponse): {
        title?: string;
        detail?: string;
    } {
        if (error.error?.nonFieldErrors && error.error.nonFieldErrors[0]) {
            const errorKey = this.getLabelKey(error.error.nonFieldErrors[0]);
            if (this.translationExists(errorKey)) {
                return { detail: errorKey };
            }
        }

        if (error.error?.fieldErrors && error.error.fieldErrors[0]) {
            const fieldError = Object.entries(error.error.fieldErrors[0]).map((entry) =>
                (entry[1] as string[]).map((value) => `${entry[0]}_${value}`.toLowerCase()),
            )[0][0];
            const errorKey = this.getLabelKey(fieldError);
            if (this.translationExists(errorKey)) {
                return { detail: errorKey };
            }
        }

        if (error?.error?.message && this.translationExists(error?.error?.message)) {
            return {
                title: error.error.message + '.title',
                detail: error.error.message + '.detail',
            };
        }

        return {};
    }

    private translationExists(key: string): boolean {
        return this.translateService.instant(key) !== key;
    }

    private getLabelKey(errorCode: string): string {
        return 'errors.' + toCamelCase(errorCode);
    }
}
