import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { MS_CLARITY_PROJECT_ID } from './tokens/ms-clarity-project-id.token';

@NgModule({})
export class MsClarityModule {
    constructor(@Inject(MS_CLARITY_PROJECT_ID) projectId: string | undefined) {
        // NOTE: this is a porting of the script tag from the original clarity script

        // Create script element
        const scriptElement = document.createElement('script');
        scriptElement.async = true;
        scriptElement.src = `https://www.clarity.ms/tag/${projectId}`;

        // Insert script element before the first script tag in the document
        const firstScriptElement = document.getElementsByTagName('script')[0];
        firstScriptElement.parentNode?.insertBefore(scriptElement, firstScriptElement);
    }

    static forRoot(config: { projectId?: string }): ModuleWithProviders<MsClarityModule> {
        return {
            ngModule: MsClarityModule,
            providers: [
                {
                    provide: MS_CLARITY_PROJECT_ID,
                    useValue: config.projectId,
                },
            ],
        };
    }
}
