import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, finalize, Observable, tap } from 'rxjs';
import { AuthService, ProfileResponse } from '@app/core/api';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private readonly identitySubject$ = new BehaviorSubject<ProfileResponse | null>(null);
    readonly identity$ = this.identitySubject$.asObservable();

    private readonly identityFetchingSubject$ = new BehaviorSubject<boolean>(false);
    readonly identityFetching$ = this.identityFetchingSubject$.asObservable();

    constructor(private authService: AuthService) {}

    /* As of now, we check the user is logged in by calling the profile API.
     * If it returns 401, then the user is not logged in or the token is not
     * valid anymore. Otherwise, we save the user information.
     *
     * If a 401 is returned, the HTTP interceptor will redirect the user to the login page.
     */
    fetchAndSetProfile(): Observable<ProfileResponse> {
        this.identityFetchingSubject$.next(true);

        return this.authService.getProfile({}).pipe(
            tap((profile) => this.identitySubject$.next(profile)),
            catchError(() => {
                this.identitySubject$.next(null);
                return EMPTY;
            }),
            finalize(() => this.identityFetchingSubject$.next(false)),
        );
    }

    logout(): Observable<void> {
        return this.authService.logout().pipe(
            tap(() => {
                this.identitySubject$.next(null);
            }),
        );
    }
}
