import { Directive, ElementRef, Inject, InjectionToken, Input, OnInit, Renderer2 } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { round } from 'lodash';

export const htmlFontSize = new InjectionToken<number>('htmlFontSize');

function getComputedSize(value: number | undefined, htmlFontSize: number): number | undefined {
    return value == null ? value : round(value * htmlFontSize, 2);
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[doableImg]',
    standalone: true,
})
export class ImageFontSizeDirective extends NgOptimizedImage implements OnInit {
    override ngOnInit(): void {
        /* NOTE: changing the value of input properties in the ngOnInit method is not recommended,
         * still it is the only way to change the value of the input properties before the ngOnInit
         * of the parent class. */
        this.height = getComputedSize(this.height, this.htmlFontSize);
        this.width = getComputedSize(this.width, this.htmlFontSize);
        super.ngOnInit();
    }

    constructor(@Inject(htmlFontSize) private htmlFontSize: number) {
        super();
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[doableRawImg]',
    standalone: true,
})
export class RawImageFontSizeDirective implements OnInit {
    @Input() height?: number;
    @Input() width?: number;

    ngOnInit(): void {
        const computedHeight = getComputedSize(this.height, this.htmlFontSize);
        const computedWidth = getComputedSize(this.width, this.htmlFontSize);

        this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${computedHeight}`);
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${computedWidth}`);
    }

    constructor(
        @Inject(htmlFontSize) private htmlFontSize: number,
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}
}
